import React, { useContext, useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/url'
import AuthContext from '../../Store/auth-context'
import styles from './Login.module.css'

const Login = () => {
    const authCtx = useContext(AuthContext)
    const [username, setUsername] = useState('')
    const [passcode, setPasscode] = useState('')
    const [isUsernameVerified, setIsUsernameVerified] = useState(false)
    const [verifyingUsername, setVerifyingUsername] = useState(false)
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)


    const validateAuthUsername = async (e) => {
        e.preventDefault()
        setVerifyingUsername(true)
        const validateUsernameResponse = await fetch(BASE_URL_API + "/validateUsername",
            {
                method: "POST",
                body: JSON.stringify({
                    username: username
                })
            });

        if (!validateUsernameResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const validateUsernameRespo = await validateUsernameResponse.json()
            if (validateUsernameRespo.status === "success") {
                setIsUsernameVerified(true)
            } else {
                setIsUsernameVerified(false)
                toast.error(`Invalid username`)
            }
            console.log(validateUsernameRespo)
        }
        setVerifyingUsername(false)
    }

    const auth = async (e) => {
        console.log('authenticating.. ')
        e.preventDefault()
        setIsAuthenticating(true)
        if (username !== '' && passcode !== '') {
            const authResponse = await fetch(BASE_URL_API + "/auth",
                {
                    method: "POST",
                    body: JSON.stringify({
                        username: username,
                        password: passcode
                    })
                })

            if (!authResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const authRespo = await authResponse.json()
                if (authRespo.status === "success") {
                    authCtx.login(authRespo?.authRespo?.authToken, authRespo?.authRespo?.clientIdentifier, authRespo?.authRespo?.username)
                } else {

                }
                console.log(authRespo)
            }
        } else {
            alert("Invalid credentials")
        }
        setIsAuthenticating(false)
    }


    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.formWrapper}>

                    {isUsernameVerified === false ?
                        <form className={styles.formContainer} onSubmit={validateAuthUsername} >
                            <div className={styles.usernameWrapper}>
                                <input placeholder="Please enter username... "
                                    value={username}
                                    autoFocus onChange={(e) => setUsername(e.target.value)}
                                    readOnly={verifyingUsername}
                                />
                            </div>
                            <div className={styles.nextBtnWrapper}>
                                {verifyingUsername === true ?
                                    <button>Validating...</button>
                                    :
                                    <button>Next</button>
                                }
                            </div>
                        </form>
                        :
                        <form className={styles.formContainer} onSubmit={auth} >
                            <div className={styles.usernameWrapper}>
                                <input placeholder="Please enter username... " value={username} readOnly onFocus={() => setIsUsernameVerified(false)} />
                            </div>
                            <div className={styles.password}>
                                <div className={styles.passwordField}>
                                <input placeholder="Please enter password... "
                                    autoFocus
                                    onChange={(e) => setPasscode(e.target.value)}
                                    type={isPasswordVisible === true ? `text` : `password`}
                                />
                                </div>
                                <div className={styles.passwordVisibility} onClick={() => setIsPasswordVisible(!isPasswordVisible)} >
                                    {isPasswordVisible === true ? <FaEye size={16} color={"#a5a5a5"} /> : <FaEyeSlash size={16} color={"#a5a5a5"} />}
                                </div>
                            </div>
                            <div className={styles.actionBtnWrapper}>
                                <div className={styles.loginBtnWrapper}>
                                    {isAuthenticating === true ?
                                        <button>Logging...</button>
                                        :
                                        <button>Login</button>
                                    }
                                </div>
                                <div className={styles.cancelBtnWrapper}>
                                    <button onClick={() => setIsUsernameVerified(false)}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    }




                </div>
            </div>
        </div>
    )
}

export default Login