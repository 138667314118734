import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL_API } from '../../References/url';
import AuthContext from '../../Store/auth-context';
import { FaCogs, FaPalette, FaHeadset } from 'react-icons/fa';
import { motion } from 'framer-motion';
import styles from './EShopHome.module.css';
import { Link } from 'react-router-dom';

const EShopHome = () => {
    const authCtx = useContext(AuthContext)
    const [allShops, setAllShops] = useState([]);
    const [allFilteredShops, setAllFilteredShops] = useState([]);

    const getAllEShops = async () => {
        const getAllEShopsResponse = await fetch(BASE_URL_API + "/getAllEShops", {
            method: 'POST',
            body: JSON.stringify({
                token: authCtx.token,
                clientIdentifier: authCtx.clientIdentifier,
            }),
        });

        if (!getAllEShopsResponse.ok) {
            console.log("Something went wrong: Server Error");
        } else {
            const getAllEShopsRespo = await getAllEShopsResponse.json();
            if (getAllEShopsRespo.status === "success") {
                setAllShops(getAllEShopsRespo?.response);
                setAllFilteredShops(getAllEShopsRespo?.response);
            } else {
                if (getAllEShopsRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setAllShops([]);
                }
            }
            console.log(getAllEShopsRespo);
        }
    };

    useEffect(() => {
        getAllEShops();
    }, []);

    return (
        <div className={styles.pageContainer}>
            <header className={styles.header}>
                <motion.div className={styles.pageTitle} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                    Build Your E-Shop with a Single Click
                </motion.div>
                <motion.p className={styles.subTitle} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.2 }}>
                    Create a professional e-commerce website and start selling online in minutes. No coding skills required!
                </motion.p>
                <motion.div className={styles.ctaButton} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.4 }}>
                    <Link to={`/create-e-shop`}>
                        Create Your Shop Now
                    </Link>
                </motion.div>
            </header>

            <section className={styles.aboutContainer}>
                <motion.h2 className={styles.sectionTitle} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                    Why Choose Us?
                </motion.h2>
                <motion.p className={styles.aboutText} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.2 }}>
                    We offer an intuitive, easy-to-use platform to create and manage your e-commerce store. Our platform is designed for growth, making it easy to set up, customize, and start selling with minimal effort.
                </motion.p>
                <div className={styles.benefitsContainer}>
                    <motion.div className={styles.benefitCard} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.3 }}>
                        <FaCogs className={styles.icon} />
                        <h3>Easy Setup</h3>
                        <p>Create your store in minutes with simple steps. No coding skills needed!</p>
                    </motion.div>
                    <motion.div className={styles.benefitCard} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.4 }}>
                        <FaPalette className={styles.icon} />
                        <h3>Customizable Themes</h3>
                        <p>Choose from a variety of themes that reflect your brand identity and style.</p>
                    </motion.div>
                    <motion.div className={styles.benefitCard} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
                        <FaHeadset className={styles.icon} />
                        <h3>24/7 Support</h3>
                        <p>Get assistance anytime. Our support team is always here to help you succeed.</p>
                    </motion.div>
                </div>
            </section>

            <section className={styles.shopContainer}>
                <motion.h2 className={styles.sectionTitle} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                    Existing Shops
                </motion.h2>
                <motion.p className={styles.shopDescription} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.2 }}>
                    Join hundreds of businesses that have already created their shops and started selling online. Here are some of the shops that have been launched using our platform:
                </motion.p>

                {allFilteredShops.length > 0 ? (
                    <div className={styles.shopGrid}>
                        {allFilteredShops.map((shop, index) => (
                            <motion.div
                                key={index}
                                className={`${styles.shopCard} ${styles.fadeIn}`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1, delay: index * 0.2 }}
                            >
                                <div className={styles.shopImage}>
                                    <img
                                        src={shop.shopImage || "https://via.placeholder.com/300"}
                                        alt={shop.shopName}
                                        className={styles.image}
                                    />
                                </div>
                                <div className={styles.shopDetails}>
                                    <div className={styles.shopName}>{shop.shopName}</div>
                                    <p className={styles.shopDescription}>{shop.shopDescription}</p>
                                    <motion.a
                                        href={`https://shop.qnnox.com/${shop.shopName}`}
                                        target="_blank"
                                        className={styles.shopButton}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        Visit Shop
                                    </motion.a>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                ) : (
                    <motion.p className={styles.noShops} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                        No shops available at the moment.
                    </motion.p>
                )}
            </section>
        </div>
    );
};

export default EShopHome;
