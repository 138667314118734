import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL_API } from '../../References/url';
import AuthContext from '../../Store/auth-context';
import styles from './AnalysisHome.module.css';
import { Bar, Pie } from 'react-chartjs-2';
import { motion } from 'framer-motion';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import TopCitiesMap from '../../Components/Analysis/TopCitiesMap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const AnalysisHome = () => {
    const authCtx = useContext(AuthContext);
    const [analyticsData, setAnalyticsData] = useState({
        monthlyPointsTrend: [],
        pointsByOrderStatus: [],
        avgPointsToValueRatio: {},
        percentZeroPoints: {},
        pointsPerOrder: {},
        avgOrderValue: {},
        totalPointsEarned: {},
        avgPointsPerOrder: {},
        totalPointsRedeemed: {},
    });

    const [loading, setLoading] = useState(true);

    const getAnalytics = async () => {
        try {
            const response = await fetch(`${BASE_URL_API}/getAnalytics`, {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: authCtx.clientIdentifier,
                }),
            });

            if (!response.ok) throw new Error('Server Error');
            const data = await response.json();

            if (data.status === 'success') {
                setAnalyticsData(data.data);
            } else {
                if (data?.message === "token Expired, please login again!") {
                    authCtx.logout()
                }
                console.error('Analytics fetch failed:', data.message);
            }
        } catch (error) {
            console.error('Error fetching analytics:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnalytics();
    }, []);

    if (loading) {
        return <div className={styles.loading}>Loading...</div>;
    }

    const statsData = [
        { title: 'Total Sales', definition: analyticsData?.totalSales?.definition, value: analyticsData?.totalSales?.data[0]?.totalSales || '-' },
        { title: 'Total Points Earned', definition: analyticsData?.totalPointsEarned?.definition, value: analyticsData?.totalPointsEarned?.data[0]?.totalPointsEarned || '-' },
        { title: 'Total Points Redeemed', definition: analyticsData?.totalPointsRedeemed?.definition, value: analyticsData?.totalPointsRedeemed?.data[0]?.totalPointsRedeemed || '-' },
        { title: 'Points to Value Ratio', definition: analyticsData?.pointsToValueRatio?.definition, value: parseFloat(analyticsData?.pointsToValueRatio?.data[0]?.avgPointsToValueRatio).toFixed(2) || '-' },
        { title: 'Percent Zero Points', definition: analyticsData?.percentZeroPoints?.definition, value: parseFloat(analyticsData?.percentZeroPoints?.data[0]?.percentZeroPoints).toFixed(2) || '-' },
        { title: 'Avg. Points Per Order', definition: analyticsData?.avgPointsPerOrder?.definition, value: parseFloat(analyticsData?.avgPointsPerOrder?.data[0]?.avgPointsPerOrder).toFixed(0) || '-' },
        { title: 'Avg Order Value', value: analyticsData?.avgOrderValue?.data?.avgOrderValue || '-' },
        { title: 'Total Delivered', value: analyticsData?.pointsByOrderStatus?.data?.totalPoints || '-' },
    ];

    const barData = {
        labels: Array.isArray(analyticsData?.monthlyPointsTrend?.data)
            ? analyticsData.monthlyPointsTrend.data.map((item) => item.month)
            : [],
        datasets: [
            {
                label: 'Points Earned Per Month',
                data: Array.isArray(analyticsData?.monthlyPointsTrend?.data)
                    ? analyticsData.monthlyPointsTrend.data.map((item) => item.totalPoints)
                    : [],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const pieData = {
        labels: analyticsData?.pointsByOrderStatus?.data?.map((item) => item.currentStatus) || [],
        datasets: [
            {
                label: 'Points by Order Status',
                data: analyticsData?.pointsByOrderStatus?.data?.map((item) => item.totalPoints) || [],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            },
        ],
    };

    // Data for Top Cities by Points
    const topCitiesBarData = {
        labels: Array.isArray(analyticsData?.topCitiesByPoints?.data)
            ? analyticsData.topCitiesByPoints.data.map((item) =>
                item.addressCity.trim() !== "" ? item.addressCity : "Unknown City"
            )
            : [],
        datasets: [
            {
                label: 'Points Earned by City',
                data: Array.isArray(analyticsData?.topCitiesByPoints?.data)
                    ? analyticsData.topCitiesByPoints.data.map((item) =>
                        parseInt(item.totalPoints) || 0
                    )
                    : [],
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
        ],
    };


    const renderEmptyMessage = (data, category) => {
        if (!data || data.length === 0) {
            return (
                <div className={styles.emptyMessage}>
                    No records found under {category}.
                </div>
            );
        }
        return null;
    };

    console.log(analyticsData)

    return (
        <div className={styles.mainWrapper}>
            {/* Header */}
            <div className={styles.mainHeaderWrapper}>
                <div>
                    <div className={styles.bigTxt}>Analysis</div>
                    <div className={styles.smallTxt}>
                        Understand how your users are responding to your programs
                    </div>
                </div>
                <div>
                    {/* <select>
                        <option>This Week</option>
                        <option>Last Week</option>
                    </select> */}
                </div>
            </div>

            {/* Stats Section */}
            <div>
                <div className={styles.statsTxt}>Stats</div>
                <div className={styles.tilesWrapper}>
                    {statsData.map((stat, index) => (
                        <motion.div
                            key={index}
                            className={styles.eachCardWrapper}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                        >
                            <div className={styles.eachCardContainer}>
                                <div className={styles.cardHeader}>
                                    <div className={styles.cardTitle}>
                                        {stat.title}
                                        <div className={styles.cardDefinition}>{stat.definition}</div>
                                    </div>
                                    <div className={styles.cardIcon}></div>
                                </div>
                                <div className={styles.cardBody}>
                                    <div className={styles.valueTxt}>{stat.value}</div>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
                {renderEmptyMessage(statsData, "Stats")}
            </div>


            {/* Top Cities by Points Section */}
            <div className={styles.recentTransactionsWrapper}>

                <div className={styles.statsTxt}>Top Cities by Points</div>
                <div className={styles.graphWrapperCity}>
                    <motion.div
                        className={styles.graphContainer}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                    >
                        <Bar data={topCitiesBarData} />
                    </motion.div>
                    {renderEmptyMessage(analyticsData?.topCitiesByPoints?.data, "Top Cities by Points")}
                </div>
            </div>

            {/* <div>
                <h3>Top Cities by Points</h3>
                <TopCitiesMap data={analyticsData?.topCitiesByPoints?.data || []} />
            </div> */}


            {/* Graph Section */}
            <div className={styles.graphWrapper}>
                <motion.div
                    className={styles.graphContainer}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <Bar data={barData} />
                </motion.div>
                {renderEmptyMessage(analyticsData?.monthlyPointsTrend?.data, "Monthly Points Trend")}

                <motion.div
                    className={styles.graphContainer}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <Pie data={pieData} />
                </motion.div>
                {renderEmptyMessage(analyticsData?.pointsByOrderStatus?.data, "Points by Order Status")}
            </div>

            {/* Recent Transactions Section */}
            <div className={styles.recentTransactionsWrapper}>
                <div className={styles.statsTxt}>Recent Transactions</div>
                {/* Placeholder for detailed transactions */}

                <div className={styles.headerWrapper}>
                    <div className={styles.sn}>#</div>
                    <div className={styles.transactionTitle}>Order Id</div>
                    <div className={styles.productsTotal}>Amount</div>
                    <div className={styles.addressCity}>City</div>
                    <div className={styles.paymentMethod}>Pay Mode</div>
                    <div className={styles.totalPointsEarned}>Points</div>
                    <div className={styles.placedOn}>Time</div>
                </div>

                {analyticsData?.recentTransactions?.data?.length > 0 ? (
                    analyticsData.recentTransactions.data.map((transaction, index) => (
                        <motion.div key={index} className={styles.transactionCard}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                        >
                            <div className={styles.sn}>{index + 1}.</div>
                            <div className={styles.transactionTitle}>{transaction.orderIdentifier}</div>
                            <div className={styles.productsTotal}>₹{transaction.productsTotal}</div>
                            <div className={styles.addressCity}>{transaction.addressCity}</div>
                            <div className={styles.paymentMethod}>{transaction.paymentMethod}</div>
                            <div className={styles.totalPointsEarned}>{transaction.totalPointsEarned}</div>
                            <div className={styles.placedOn}>{transaction.placedOn}</div>
                        </motion.div>
                    ))
                ) : (
                    <div className={styles.emptyMessage}>No recent transactions found.</div>
                )}
            </div>
        </div>
    );
};

export default AnalysisHome;
