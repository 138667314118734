import React, { useState, useEffect, useContext } from 'react';
import styles from './CreateEShop.module.css';
import { motion } from 'framer-motion';
import { BASE_URL_API } from '../../References/url';
import AuthContext from '../../Store/auth-context';
import { useNavigate } from 'react-router';

const CreateEShop = () => {
    // State for shop name and dialog visibility
    const authCtx = useContext(AuthContext)
    const [shopName, setShopName] = useState('')
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const nav = useNavigate()

    // Handle shop name input and format it
    const handleInputChange = (e) => {
        const value = e.target.value;
        const formattedValue = value
            .trim()
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '');
        setShopName(formattedValue);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (shopName === '') {
            alert('Shop name cannot be empty!');
            return;
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setIsDialogOpen(true);
        }, 500); // Simulate a delay for creating the shop
    };

    // Handle confirmation
    const handleConfirm = () => {
        createEShop()
    }
    const createEShop = async () => {
        const createEShopResponse = await fetch(BASE_URL_API + "/createEShop", {
            method: 'POST',
            body: JSON.stringify({
                token: authCtx.token,
                clientIdentifier: authCtx.clientIdentifier,
                shopName : shopName
            }),
        });

        if (!createEShopResponse.ok) {
            console.log("Something went wrong: Server Error");
        } else {
            const createEShopRespo = await createEShopResponse.json();
            if (createEShopRespo.status === "success") {
                setIsDialogOpen(false)
                nav(`/e-shops`)
            } else {
                if (createEShopRespo.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {

                }
            }
            console.log(createEShopRespo);
        }
    }

    // Handle cancellation
    const handleCancel = () => {
        setIsDialogOpen(false);
    };

    // Close dialog on escape key press
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setIsDialogOpen(false);
            }
        };
        window.addEventListener('keydown', handleEscape);
        return () => window.removeEventListener('keydown', handleEscape);
    }, []);

    // Close dialog if clicked outside the dialog
    const handleDialogClick = (e) => {
        if (e.target === e.currentTarget) {
            setIsDialogOpen(false);
        }
    };

    return (
        <motion.div
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className={styles.title}>Create Your E-Shop</div>
            <form className={styles.formContainer} onSubmit={handleSubmit}>
                <div className={styles.label}>Shop Name</div>
                <input
                    type="text"
                    id="shopName"
                    name="shopName"
                    value={shopName}
                    onChange={handleInputChange}
                    className={styles.inputField}
                    placeholder="Enter shop name"
                    autoFocus
                />
                <button type="submit" className={styles.submitButton}>
                    {loading ? 'Creating...' : 'Create Shop'}
                </button>
            </form>

            {/* Modal Dialog */}
            {isDialogOpen && (
                <div
                    className={styles.dialogOverlay}
                    onClick={handleDialogClick}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <motion.div
                        className={styles.dialogBox}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ type: 'spring', stiffness: 300 }}
                    >
                        <div className={styles.confirmTitle}>Confirm Your Shop Name</div>
                        <div className={styles.shopName} >{shopName}</div>
                        <div className={styles.dialogActions}>
                            <button onClick={() => handleConfirm()} className={styles.confirmButton}>
                                Confirm
                            </button>
                            <button onClick={handleCancel} className={styles.cancelButton}>
                                Cancel
                            </button>
                        </div>
                    </motion.div>
                </div>
            )}
        </motion.div>
    );
};

export default CreateEShop;
